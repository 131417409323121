/**
 * Video overlay
 *
 * @selector [data-video-overlay]
 * @enabled true
 */
import BaseModule from 'app/modules/BaseModule';
import Plyr from 'plyr';

const DEFAULT_OPTIONS = {};

export default class Video extends BaseModule {
	constructor() {
		super();
	}

	init(element) {
		this.el = element;
		this.isOverlayVisible = false;
		this.player = false;

		this.videoEl = element.querySelector('.Player');
		this.closeEl = element.querySelector('.BubbleVideo--closebutton');
		this.videoCover = element.querySelector('.BubbleVideo');
		this.videoProvider = this.videoEl.getAttribute('data-plyr-provider');
		this.videoID = this.videoEl.getAttribute('data-plyr-embed-id');

		this.bindEvents();

		return this;
	}

	bindEvents() {
		this.on(this.videoCover, 'click', this.toggleOverlay.bind(this));
		this.on(this.closeEl, 'click', this.hideOverlay.bind(this));
	}

	toggleOverlay() {
		this.isOverlayVisible ? this.hideOverlay() : this.showOverlay();
	}

	hideOverlay() {
		console.log('hide overlay');
		if (this.player) {
			this.player.pause();
		}
		this.el.classList.remove('show');
		this.isOverlayVisible = false;
	}

	showOverlay() {
		console.log('show overlay');
		this.el.classList.add('show');
		if (!this.player) {
			this.player = new Plyr(this.videoEl, {
				autoplay: true,
				playsinline: true
			});
		}

		this.isOverlayVisible = true;
	}

	destroy() {
		super.destroy();
	}
}

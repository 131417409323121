/**
 * FooterInView
 *
 * @selector [data-footer-inview]
 * @enabled true
 */

import BaseModule from 'app/modules/BaseModule';
import scrollMonitor from 'scrollmonitor';

const DEFAULT_OPTIONS = {
	burgerEl: '[data-menu-overlay-toggle]',
	muteEl: '[data-menu-mute-toggle]',
	classActive: 'is-over-footer'
};

export default class FooterInView extends BaseModule {
	constructor() {
		super();
	}

	init(element) {
		this.el = element;
		this.burgerEl = document.querySelector(DEFAULT_OPTIONS.burgerEl);
		// this.muteEl = document.querySelector(DEFAULT_OPTIONS.muteEl);
		// TODO: move to method, resize event, use fastdom
		this.wh = window.innerHeight;

		// Scroll
		this.elementWatcher = scrollMonitor.create(this.el, (this.wh / -2) * 1.1);

		this.elementWatcher.enterViewport(() => {
			this.burgerEl.classList.add(DEFAULT_OPTIONS.classActive);
			// this.muteEl.classList.add(DEFAULT_OPTIONS.classActive);
		});

		this.elementWatcher.exitViewport(() => {
			this.burgerEl.classList.remove(DEFAULT_OPTIONS.classActive);
			// this.muteEl.classList.remove(DEFAULT_OPTIONS.classActive);
		});

		return this;
	}

	destroy() {
		this.elementWatcher.destroy();
		super.destroy();
	}
}

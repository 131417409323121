/**
 * CookieNotification
 *
 * @selector [data-js="CookieNotification"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { setCookie, getCookie } from 'app/util/cookie';
import { once } from 'app/util/once';

const config = {
	optionsAttr: 'data-options',
	cookieName: 'tn-website-consent-seen'
};

export default function CookieNotification() {
	// Private vars
	const instance = {};
	let acceptBtn;

	// Private methods
	const onAccept = () => {
		setCookie(config.cookieName, true);

		once(instance.el, 'transitionend', () => {
			instance.destroy();
			instance.el.remove();
		});

		instance.setState('');
	};

	const bindEvents = () => {
		acceptBtn.addEventListener('click', onAccept);
	};

	const unbindEvents = () => {
		acceptBtn.removeEventListener('click', onAccept);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		acceptBtn = instance.ref('accept');

		if (!getCookie(config.cookieName)) {
			// Cookie is not set. Show cookie notification
			instance.setState('visible');
		} else {
			// Cookie already accepted.
			instance.el.remove();
			return;
		}

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}

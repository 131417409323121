/**
 * PageBackgroundLines
 *
 * @selector .site-content
 * @enabled true
 */

import BaseModule from 'app/modules/BaseModule';
import RandomBackground from 'app/content/RandomBackground';

const DEFAULT_OPTIONS = {};

export default class PageBackgroundLines extends BaseModule {
	constructor() {
		super();
	}

	init(element) {
		this.el = element;
		this.randomLines = new RandomBackground({
			target: this.el,
			zIndex: '-1',
			animFromRightToLeft: true
		});		
	}

	destroy() {
		super.destroy();
	}
}

/**
 * BubbleContainer
 *
 * @selector [data-animate]
 * @enabled true
 */
import BaseModule from 'app/modules/BaseModule';
import scrollMonitor from 'scrollmonitor';

const DEFAULT_OPTIONS = {
	isVisible: 'is-visible',
	isHidden: 'is-hidden',
	idleEl: '.Bubble--idle',
	animatedEl: [
		'.BubbleH1',
		'.BubbleH2',
		'.BubbleImage',
		'.Button',
		'.BubbleBlockquote',
		'.BubbleText',
		'.BubbleVideoWrapper',
		'.Avatar'
	]
};

export default class BubbleContainer extends BaseModule {
	constructor() {
		super();
	}

	init(element) {
		this.el = element;
		this.animEls = this.el.querySelectorAll(DEFAULT_OPTIONS.animatedEl);
		this.idleEl = this.el.querySelector(DEFAULT_OPTIONS.idleEl);

		var elementWatcher = scrollMonitor.create(this.el, -(window.innerHeight / 9.2));
		elementWatcher.enterViewport(() => {
			this.runAnimation(450);
		});

		elementWatcher.exitViewport(() => {
			if(elementWatcher.isAboveViewport){
				this.runAnimation(0);
			}
		});		

		return this;
	}

	runAnimation(delay){
		setTimeout(() => {
			this.idleEl.classList.add(DEFAULT_OPTIONS.isHidden)
			for (var i = 0; i < this.animEls.length; ++i) {
				this.animEls[i].classList.add(DEFAULT_OPTIONS.isVisible);
			}
		}, delay);
	}
}

/**
 * Floating Label
 *
 * @selector [data-js=form]
 * @enabled true
 */
import BaseModule from 'app/modules/BaseModule';

const DEFAULT_OPTIONS = {};

export default class FloatingLabel extends BaseModule {
	constructor() {
		super();
	}

	init(element) {
		this.el = element;

		this.floatContainers = document.querySelectorAll('.BasicInput');

		this.floatContainers.forEach(element => {
			const targetEl = element.querySelector('label');

			if(targetEl){
				const targetID = targetEl.getAttribute('for');
				const targetInput = document.getElementById(targetID);
				const inputType = targetInput.getAttribute('type');
	
				if (inputType != 'file' && inputType != 'range') {
	
					if (targetEl.value) {
						element.classList.add('has-value');
					}
	
					this.bindEvents(element);
				}				
			}

		});

		return this;
	}

	handleFocus(e) {
		const target = e.target;
		target.parentNode.classList.add('has-value');
	}

	handleBlur(e) {
		const target = e.target;
		if (!target.value) {
			target.parentNode.classList.remove('has-value');
		}
	}

	bindEvents(element) {
		const targetID = element.querySelector('label').getAttribute('for');
		const targetEl = document.getElementById(targetID);

		targetEl.addEventListener('focus', this.handleFocus);
		targetEl.addEventListener('blur', this.handleBlur);
	}

	destroy() {
		super.destroy();
	}
}

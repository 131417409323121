/**
 * Styled input select
 *
 * @selector .BasicInput .Input--select
 * @enabled true
 */
import BaseModule from 'app/modules/BaseModule';

export default class StyledSelectInput extends BaseModule {
	constructor() {
		super();
	}

	init(element) {
		this.el = element;

		this.on('change', this.handleChange.bind(this));

		return this;
	}

	handleChange(e) {
		let optionvalue = e.target.options[event.target.selectedIndex].getAttribute('value');

		if(optionvalue == '' || optionvalue == null || optionvalue === false){
			this.el.classList.remove('has-value');
		} else {
			this.el.classList.add('has-value');
		}
	}

	destroy() {
		super.destroy();
	}
}

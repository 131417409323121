/**
 * MenuOverlay
 *
 * @selector [data-menu-overlay]
 * @enabled true
 */

import BaseModule from 'app/modules/BaseModule';
import KeyCodes from 'lib/browser/KeyCodes';
import fastdom from 'fastdom';
import RandomBackground from 'app/content/RandomBackground';

import 'lib/eventlistener/closest';

const DEFAULT_OPTIONS = {
	toggleEl: '[data-menu-overlay-toggle]',
	classOpen: 'is-open',
	htmlEl: 'html',
	htmlElClass: 'menu-is-open',
	classMenuActive: 'is-active',
	subnavEl: '[data-subnav]',
	classSubnav: 'subnav-is-open',
	menuInnerEl: '.HeaderNavOverlay',
	chatBubblesEl: '.HeaderNavOverlay--BubblesWrapper',
	closeBtn: '.HeaderNavOverlay--close',
	menuBgEl: '.HeaderNavOverlay--background--section',
	menuBgSizes: ['29%', '20%', '6%', '47%'] // SMELL: Implicitly uses same length as menuBgEl elements
};

const shuffleArray = a => {
	for (let i = a.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[a[i], a[j]] = [a[j], a[i]];
	}
	return a;
};

export default class MenuOverlay extends BaseModule {
	constructor() {
		super();
	}

	init(element) {
		this.el = element;
		this.toggleEl = element.querySelector(DEFAULT_OPTIONS.toggleEl);
		this.closeEl = element.querySelector(DEFAULT_OPTIONS.closeBtn);
		this.htmlEl = document.querySelector(DEFAULT_OPTIONS.htmlEl);
		this.backgroundEl = document.querySelectorAll(DEFAULT_OPTIONS.menuBgEl);
		this.isOpen = false;
		this.isSubNavOpen = false;
		this.subNavLinks = [];

		this.menuInnerEl = element.querySelector(DEFAULT_OPTIONS.menuInnerEl);
		this.subnavEl = element.querySelectorAll(DEFAULT_OPTIONS.subnavEl);
		this.chatBubblesEl = element.querySelector(DEFAULT_OPTIONS.chatBubblesEl);

		this.setupBackgroundAnimation();

		// Open/close Main navigation
		this.on(this.toggleEl, 'click', this.toggleMenu.bind(this));
		this.on(this.closeEl, 'click', this.toggleMenu.bind(this));

		this.subnavEl.forEach((element, i) => {
			let link = new SubNavLink(this, this.subnavEl, element);
			this.subNavLinks.push(link);
		});

		return this;
	}

	toggleMenu(e) {
		e.preventDefault();

		if (this.isOpen) {
			this.closeMenu();
		} else {
			this.openMenu();
		}

		if (this.isSubNavOpen) {
			this.closeSubMenu();
		}
	}

	setupBackgroundAnimation() {
		fastdom.measure(() => {
			this.spansArray = shuffleArray(Array.prototype.slice.call(this.backgroundEl));

			// Randomize the size of the sections
			const heights = shuffleArray(DEFAULT_OPTIONS.menuBgSizes);

			this.spansArray.forEach((span, i) => {
				span.style.height = heights[i];
				span.style.width = '0%';
				span.style.transitionDelay = 0.1 + i / 12 + 's';
				span.style.transitionDuration = '0s';
			});
		});
	}

	openMenu() {
		fastdom.mutate(() => {
			this.htmlEl.classList.add(DEFAULT_OPTIONS.htmlElClass);
			this.el.classList.add(DEFAULT_OPTIONS.classOpen);
			this.randomLines = new RandomBackground({
				target: this.menuInnerEl,
				zIndex: '1',
				animFromRightToLeft: false,
				class: "inNavOverlay"
			});
			this.openAnimation();
		});
		this.isOpen = true;
	}

	closeMenu() {
		fastdom.mutate(() => {
			this.htmlEl.classList.remove(DEFAULT_OPTIONS.htmlElClass);
			this.el.classList.remove(DEFAULT_OPTIONS.classOpen);
			this.randomLines.destroy();
			this.closeAnimation();
		});
		this.isOpen = false;
	}

	closeSubMenu() {
		this.subNavLinks.forEach(element => {
			element.close();
		});
	}

	openAnimation() {
		fastdom.mutate(() => {
			this.spansArray.forEach(span => {
				span.style.width = '100%';
				span.style.transitionDuration = '0.7s';
			});
		});
	}

	closeAnimation() {
		setTimeout(() => {
			this.setupBackgroundAnimation();	
		}, 200);
	}

	destroy() {
		document.body.removeEventListener('touchmove', this.constructor.freezeScrollEvent, {
			passive: false
		});

		super.destroy();
	}
}

class SubNavLink {
	constructor(menu, parent, element) {
		this.menu = menu;
		this.links = parent;
		this.link = element;
		this.target = document.getElementById(this.link.getAttribute('href').replace(/#/, ''));
		this.isOpen = false;

		this.init();
	}

	init() {
		this.open = this.open.bind(this);
		this.link.addEventListener('click', this.open);
	}

	open(e) {
		e.preventDefault();
		
		if (this.isOpen) {
			this.close();
			return;
		}
		if (this.links.activeLink) {
			this.links.activeLink.close();
		}
		this.menu.isSubNavOpen = true;
		this.links.activeLink = this;
		this.isOpen = true;
		this.menu.chatBubblesEl.classList.add(DEFAULT_OPTIONS.classSubnav);
		this.target.classList.add(DEFAULT_OPTIONS.classSubnav);
		return false;
	}

	close() {
		this.menu.isSubNavOpen = false;
		this.isOpen = false;
		this.menu.chatBubblesEl.classList.remove(DEFAULT_OPTIONS.classSubnav);
		this.target.classList.remove(DEFAULT_OPTIONS.classSubnav);
	}
}

/**
 * IntroModal
 *
 * @selector [data-js="HeaderSearch"]
 * @enabled true
 */
import { base } from 'app/util/base';

const defaults = {
};

const config = {
	optionsAttr: 'data-options'
};

export default function IntroModal() {
	// Private vars
	const instance = {};
	let settings = {};
	let closeButton, openButtons, inner, input;

	// Private methods
	const bindEvents = () => {
		openButtons.forEach(openButton => {
			openButton.addEventListener('click', handleOpenClick);
		});
		closeButton.addEventListener('click', handleCloseClick);

		instance.el.addEventListener('click', outsideClick);
		inner.addEventListener('click', insideClick);
	};

	const unbindEvents = () => {
		openButtons.forEach(openButton => {
			openButton.removeEventListener('click', handleOpenClick);
		});
		closeButton.removeEventListener('click', handleCloseClick);

		instance.el.removeEventListener('click', outsideClick);
		inner.removeEventListener('click', insideClick);
	};

	const handleCloseClick = () => {
		instance.el.classList.remove('open');
		document.documentElement.classList.remove('search-is-open');
	};

	const handleOpenClick = () => {
		instance.el.classList.add('open');
		document.documentElement.classList.add('search-is-open');
		input.focus();
	};

	const outsideClick = (e) => {
		handleCloseClick();
	};

	const insideClick = (e) => {
		e.stopPropagation();
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);
		closeButton = instance.ref('close');
		openButtons = document.querySelectorAll('[data-ref="HeaderSearch:open"]');
		inner = instance.ref('inner');
		input = instance.ref('input');

		bindEvents();

		return instance;
	}

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
};

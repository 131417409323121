/**
 * Filter Search
 *
 * @selector .Filter--Search
 * @enabled true
 */
import BaseModule from 'app/modules/BaseModule';

export default class FilterSearch extends BaseModule {
	constructor() {
		super();
	}

	init(element) {
        this.el = element;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        // Scroll into view
        if(urlParams.has('search')){
            this.el.scrollIntoView();
        }

		return this;
    }
    
	destroy() {
		super.destroy();
	}
}

/**
 * Search Keyword Highlighter
 *
 * @selector .site-content
 * @enabled true
 */

 import BaseModule from 'app/modules/BaseModule';
 import Mark from 'mark.js';
 
 export default class SearchKeywordHighlighter extends BaseModule {
     constructor() {
        super();
     }
 
     init(element) {
        this.el = element;

        if (this.el.querySelector('.page--search') || document.body.classList.contains('page--search')) {
            return;
        }

        const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
        const query = urlParams.get('query');

        if (query) {
            let instance = new Mark(this.el);
            instance.mark(query.replace('+' , ' '), {
                element: 'mark',
                filter: function(textNode, term, counter) {
                    if (counter < 1) {
                        const range = document.createRange();
                        range.selectNode(textNode);
                        const boundingBox = range.getBoundingClientRect();
                        window.scrollTo(0, boundingBox.top - (window.innerHeight / 2));
                    }
                    return true;
                }
            });
        }
     }
 
     destroy() {
        super.destroy();
     }
 }
 
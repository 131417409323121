import { gsap } from 'gsap';

const DEFAULT_OPTIONS = {
	frequency: 4,
	max_blocks: 2,
	min_blocks: 1,
	spread_blocks_x: 30,
	elWrapper: 'js-randomBgWrapper',
	elClassName: 'js-randomBgWrapper--line',
	colors: [
		['#666b40', '#494949'],
		['#fff', '#E6E6E6']
	],
	options: {
		target: document.querySelector('body'),
		zIndex: null,
		animFromRightToLeft: true
	}
};

export default class RandomBackground {
	constructor(options) {
		this.options = Object.assign(DEFAULT_OPTIONS.options, options);

		this.target = this.options.target;
		this.height = window.innerHeight;
		this.width = window.innerWidth;

		let wrapperEl = document.createElement('div');
		let wrapperElClass = this.options.class || '';
		wrapperEl.classList.add(DEFAULT_OPTIONS.elWrapper);
		wrapperEl.classList.add(this.options.direction ? 'anim-left' : 'anim-right');
		if (wrapperElClass.length > 0) {
			wrapperEl.classList.add(wrapperElClass);
		}
		wrapperEl.style.zIndex = this.options.zIndex;

		this.wrapper = wrapperEl;
		this.target.appendChild(wrapperEl);

		// FREQUENCY BASED ON PAGE HEIGHT
		for (let i = 0; i < DEFAULT_OPTIONS.frequency; i++) {
			let blockspread =
				Math.floor(Math.random() * (DEFAULT_OPTIONS.max_blocks - DEFAULT_OPTIONS.min_blocks + 1)) +
				DEFAULT_OPTIONS.min_blocks;

			// RANDOM OUTPUT SQUARES IN GROUPS
			for (let k = 0; k < blockspread; k++) {
				let transDelay = Math.random() * (0.5 - 0) + 0;
				let randomRight = Math.random() * this.width;
				let leftOrRight = Math.random() < 0.5 ? -1 : 1;
				let randomTop = Math.random() * this.height + (k + 1 * 20);
				let box = this.createTag('span', { class: DEFAULT_OPTIONS.elClassName });
				let boxAnim = gsap.timeline({ delay: transDelay });
				let randomWidth = Math.random() * (3.5 - 1) + 1;
				let randomColorGroup =
					DEFAULT_OPTIONS.colors[Math.floor(Math.random() * DEFAULT_OPTIONS.colors.length)];

				if (leftOrRight > 0) {
					box.style.right = randomRight + DEFAULT_OPTIONS.spread_blocks_x * leftOrRight + 'px';
				} else {
					box.style.left = randomRight + DEFAULT_OPTIONS.spread_blocks_x * leftOrRight + 'px';
				}
				box.style.top = randomTop + 'px';
				box.style.background = randomColorGroup[Math.floor(Math.random() * randomColorGroup.length)];

				wrapperEl.appendChild(box);

				boxAnim.add(
					gsap.to(box, 0.9, {
						x: 0,
						scaleX: randomWidth,
						ease: 'power1.in'
					})
				);
				boxAnim.add(
					gsap.to(box, 1, {
						x: this.options.direction ? '-450%' : '450%',
						scaleX: 0,
						ease: 'power1.out'
					})
				);
			}
		}

		return this;
	}

	createTag(name, attrs) {
		let el = document.createElement(name.toString());

		!!attrs &&
			Object.keys(attrs).forEach(function(key) {
				el.setAttribute(key, attrs[key]);
			});

		return el;
	}

	destroy() {
		this.wrapper.remove();
	}
}

/**
 * ScrollToTop
 *
 * @selector .ScrollToTop
 * @enabled true
 */

import BaseModule from 'app/modules/BaseModule';
import fastdom from 'fastdom';

const DEFAULT_OPTIONS = {
	minMessages: 5,
	parent: '.site-content',
	topTrigger: '.BubbleContainer--inner',
	footerTrigger: '.site-footer',
	scrollToTopButton: '.Button',
	trackEls: ['.BubbleH1', '.BubbleH2', '.BubbleImage', '.BubbleBlockquote', '.BubbleText', '.BubbleVideoWrapper']
};

export default class ScrollToTop extends BaseModule {
	constructor() {
		super();
	}

	init(element) {
		this.el = element;
		this.button = this.el.querySelector(DEFAULT_OPTIONS.scrollToTopButton);
		this.buttonText = this.button.innerHTML;
		this.parent = document.querySelector(DEFAULT_OPTIONS.parent);
		this.topTrigger = document.querySelector(DEFAULT_OPTIONS.topTrigger);

		let els = this.parent.querySelectorAll(DEFAULT_OPTIONS.trackEls);
		this.trackedElements = Array.prototype.slice.call(els);
		this.oldMessages = 0;
		this.totalMessages = this.trackedElements.length;

		this.footerTrigger = document.querySelector(DEFAULT_OPTIONS.footerTrigger);

		if (this.totalMessages > DEFAULT_OPTIONS.minMessages) {
			window.addEventListener('scroll', () => this.validate());
			this.validate();
			this.on(this.button, 'click', this.scrollToTop.bind(this));
		}

		return this;
	}

	validate() {
		let measurements;
		fastdom.measure(() => {
			measurements = {
				top: this.topTrigger.getBoundingClientRect(),
				footer: this.footerTrigger.getBoundingClientRect(),
				elements: this.trackedElements.map(el => el.getBoundingClientRect()),
				windowHeight: window.innerHeight
			};
		});
		fastdom.mutate(() => {
			if (measurements.footer.top <= measurements.windowHeight) {
				this.el.classList.add('not-sticky');
			} else {
				this.el.classList.remove('not-sticky');
			}
			let count = 0;
			measurements.elements.forEach(el => {
				if (el.bottom < 0) count++;
			});
			if (count === 0) {
				this.el.classList.remove('show');
			} else {
				this.el.classList.add('show');
			}
			this.oldMessages = count;
			this.changeText();
		});
	}

	scrollToTop() {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}

	changeText() {
		if (this.oldMessages > 0) {
			fastdom.mutate(() => {
				this.button.innerHTML = this.oldMessages + ' ' + this.buttonText;
			});
		}
	}

	destroy() {
		super.destroy();
	}
}

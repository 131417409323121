/**
 * ImageGallery
 *
 * @selector [data-gallery]
 * @enabled true
 */

import BaseModule from 'app/modules/BaseModule';
import Chocolat from 'chocolat'; // Documentation: https://chocolat.gitbook.io/chocolat/options

const DEFAULT_OPTIONS = {
	galleryImageEl: '.chocolat-image',
	galleryTotalImagesEl: '.ChatBubbleMedia--Gallery--total',
	galleryTotalImages: null,
	galleryConfig: {
		loop: true,
		imageSize: 'scale-down',
		allowZoom: false
	}
};

export default class ImageGallery extends BaseModule {
	constructor() {
		super();
	}

	init(element) {
		// SETUP
		this.el = element;

		// INIT GALLERY
		this.gallery = Chocolat(
			this.el.querySelectorAll(DEFAULT_OPTIONS.galleryImageEl),
			DEFAULT_OPTIONS.galleryConfig
		);
		this.gallery.api = this.gallery.api;

		return this;
	}

	destroy() {
		super.destroy();
		this.gallery.api.destroy();
	}
}

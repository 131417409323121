/**
 * RangeSlider.js
 *
 * @selector .js-InputRange
 * @enabled true
 */
import BaseModule from 'app/modules/BaseModule';

const DEFAULT_OPTIONS = {
	elRangeInput: 'input[type="range"]',
	elDatalist: '.js-InputRange--datalist',
	elTargetContainer: 'label'
};

export default class RangeSlider extends BaseModule {
	constructor() {
		super();
	}

	init(element) {
		this.el = element;
		this.input = this.el.querySelector(DEFAULT_OPTIONS.elRangeInput);
		this.datalist = this.el.querySelector(DEFAULT_OPTIONS.elDatalist);
		this.targetContainer = this.el.querySelector(DEFAULT_OPTIONS.elTargetContainer);
		this.span = document.createElement('span');
		this.targetContainer.appendChild(this.span);

		this.on(this.el, 'input', this.updateValue.bind(this));

		return this;
	}

	updateValue(){
		this.span.innerHTML = this.datalist.options[this.input.value].value;
	}

	destroy() {
		super.destroy();
	}
}

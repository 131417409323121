/**
 * Flickity Carousel
 *
 * @selector [data-js="Slider"]
 * @enabled true
 */
import BaseModule from 'app/modules/BaseModule';
import Flickity from 'flickity';

const DEFAULT_OPTIONS = {
	isVisible: 'is-visible',
	sliderConfig: {
		cellAlign: 'left',
		draggable: true,
		adaptiveHeight: true,
		prevNextButtons: true,
		pageDots: false,
		selectedAttraction: 0.08,
		friction: 0.5,
		groupCells: '80%',
		arrowShape:
			'M96.999994 49.338568c0-.784312-.323125-1.533375-.860687-2.097375L67.060996 17.869132c-1.148562-1.160312-3.008-1.157375-4.153625 0-1.148562 1.157375-1.148562 3.037375 0 4.19475l24.066936 24.307811H5.937499c-1.6215 0-2.9375 1.32775-2.9375 2.966875s1.316 2.966875 2.9375 2.966875h81.03387L62.90737 76.613254c-1.148562 1.157375-1.145625 3.037375 0 4.19475 1.148562 1.157375 3.008 1.157375 4.153625 0l29.078311-29.372061c.549312-.555187.851875-1.318937.860687-2.097375z'
	}
};

export default class SliderCarousel extends BaseModule {
	constructor() {
		super();
	}

	init(element) {
		this.el = element;
		this.slider = new Flickity(this.el, DEFAULT_OPTIONS.sliderConfig);

		return this;
	}

	destroy() {
		this.slider.destroy();
		super.destroy();
	}
}

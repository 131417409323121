/** @license HV v. 2020-04-01 **/

/**
 * FileUpload
 *
 * @selector .js-FileUpload
 * @enabled true
 */
import str2dom from 'app/util/str2dom';

const config = {
	fileInputClass: '.js-FileUpload-input, input[type="file"]',
	dropzoneClass: '.js-FileUpload-dropzone',
	uploadDescClass: '.js-FileUpload-desc',
	removeFilesClass: '.js-FileUpload-removeFiles',
	errorMessageClass: '.js-FileUpload-errorMessage',
	filesizelimit: 12000000,
	filesTpl: (list) => `<span class="Input__filenames">${list}</span>`,
	fileTpl: (filename) => `<span class="Input__filename">${filename}</span>`,

	// These templates are only needed when using wordpress gravity forms.
	inputRemoveFilesTpl: `
		<button type="button" class="Input__removeFiles js-FileUpload-removeFiles">
        	<span class="visuallyhidden">Remove files</span>
        </button>
	`,
	uploadDescTpl: `
		<span class="Input__uploadDesc js-FileUpload-desc">
        	<span class="visuallyhidden">Click to select files or drop files here</span>
        </span>
	`
};

export default function FileUpload() {
	// Private vars
	const instance = {};
	let el;
	let fileInput;
	let dropzone;
	let uploadDesc;
	let filenames;
	let btnRemoveFiles;
	let filesizeok;
	let errorMessage;

	// Private methods
	const onChange = () => {
		// TODO: file validaton needed?

		// Hide hint
		uploadDesc.style.zIndex = '-1';

		// Clear list
		filenames && dropzone.removeChild(filenames);

		// Get files
		let files = Array.from(fileInput.files);
		let tmp = '';
		let totalSize = 0;

		// Show filenames
		files.forEach((file) => {
			totalSize += file.size;
			tmp += config.fileTpl(file.name);
		});

		filenames = str2dom(config.filesTpl(tmp));

		if(totalSize < config.filesizelimit){
			filenames = dropzone.appendChild(filenames);

			// show remove button
			btnRemoveFiles.style.display = 'inline-block';
			// Hide error message if size too big
			errorMessage.style.display = 'none';
		} else {
			filenames = null;
			errorMessage.style.display = 'inline-block';
		}
	};

	const onRemoveFiles = () => {
		fileInput.value = '';

		// hide remove button
		btnRemoveFiles.style.display = '';

		// Show hint
		uploadDesc.style.zIndex = '';

		// Clear list
		filenames && dropzone.removeChild(filenames);
		filenames = null;
	};

	const bindEvents = () => {
		fileInput.addEventListener('change', onChange);
		btnRemoveFiles.addEventListener('click', onRemoveFiles);
	};
	const unbindEvents = () => {
		fileInput.removeEventListener('change', onChange);
		btnRemoveFiles.removeEventListener('click', onRemoveFiles);
	};

	// Public vars
	instance.ns = 'FileUpload';

	// Public methods
	instance.init = (element) => {
		el = element;
		fileInput = el.querySelector(config.fileInputClass);

		if (!fileInput) {
			return;
		}

		// Not really a dropzone, meaning there are no drag/drop events bound. We drop onto the input element.
		dropzone = el.querySelector(config.dropzoneClass);

		uploadDesc = el.querySelector(config.uploadDescClass);
		btnRemoveFiles = el.querySelector(config.removeFilesClass);
		errorMessage = el.querySelector(config.errorMessageClass);

		bindEvents();

		return instance;
	}

	instance.destroy = () => {
		unbindEvents()
	};

	return instance;
};

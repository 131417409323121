import contextTrigger from 'lib/util/contextTrigger';
import ModuleManager from 'lib/util/ModuleManager';



import RangeSlider from '00-base/Input/RangeSlider';



import fileUpload from '00-base/Input/fileUpload';



import BubbleContainerAnim from '02-content/BubbleContainer/BubbleContainerAnim';



import ImageOverlay from '02-content/BubbleImage/ImageOverlay';



import BubbleVideo from '02-content/BubbleVideo/BubbleVideo';



import SelectStyling from '02-content/ContactForm/SelectStyling';



import floatingLabels from '02-content/ContactForm/floatingLabels';



import CookieNotification from '02-content/CookieNotice/CookieNotification';



import FilterSearch from '02-content/Filter/FilterSearch';



import Slider from '02-content/Slider/Slider';



import Footer from '03-global/Footer/Footer';



import HeaderNav from '03-global/HeaderNav/HeaderNav';



import HeaderSearch from '03-global/HeaderSearch/HeaderSearch';



import ScrollToTop from '03-global/ScrollToTop/ScrollToTop';



import PageBackgroundLines from '_preview/PageBackgroundLines';



import Pagination from '_preview/Pagination';



import SearchKeywordHighlighter from '_preview/SearchKeywordHighlighter';



export default (function() {
	let time = new Date();


	contextTrigger.add('.js-InputRange', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(RangeSlider));
	});


	contextTrigger.add('.js-FileUpload', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(fileUpload));
	});


	contextTrigger.add('[data-animate]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(BubbleContainerAnim));
	});


	contextTrigger.add('[data-gallery]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ImageOverlay));
	});


	contextTrigger.add('[data-video-overlay]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(BubbleVideo));
	});


	contextTrigger.add('.BasicInput .Input--select', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(SelectStyling));
	});


	contextTrigger.add('[data-js=form]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(floatingLabels));
	});


	contextTrigger.add('[data-js="CookieNotification"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(CookieNotification));
	});


	contextTrigger.add('.Filter--Search', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(FilterSearch));
	});


	contextTrigger.add('[data-js="Slider"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Slider));
	});


	contextTrigger.add('[data-footer-inview]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Footer));
	});


	contextTrigger.add('[data-menu-overlay]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(HeaderNav));
	});


	contextTrigger.add('[data-js="HeaderSearch"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(HeaderSearch));
	});


	contextTrigger.add('.ScrollToTop', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ScrollToTop));
	});


	contextTrigger.add('.site-content', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(PageBackgroundLines));
	});


	contextTrigger.add('.page--team-members,.page--stories, .page--search', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Pagination));
	});


	contextTrigger.add('.site-content', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(SearchKeywordHighlighter));
	});



	contextTrigger.validate(document.body);

	console.log('Selecting components took: ', new Date() - time, 'ms');
}());
